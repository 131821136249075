import React, { useEffect, useState } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import Singapore from "./img/Singapore.png";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const Countdown = () => {
  const target = new Date("October 2,2024 23:59:59").getTime();
  const [size, setSize] = useState({ width: 40, height: 60 });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    if (!!window) {
      if (window.innerWidth < 767) {
        setSize({
          width: 30,
          height: 50,
        });
      } else {
        setSize({
          width: 40,
          height: 60,
        });
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        height: "100%",
      }}
    >
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: 18,
          padding: "10px 0px",
        }}
      >
        Countdown has begun !!
      </p>
      <FlipClockCountdown
        to={target}
        showLabels={true}
        labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
        labelStyle={{
          fontSize: 14,
          fontWeight: 500,
          textTransform: "uppercase",
          color: "black",
        }}
        digitBlockStyle={{
          width: size.width,
          height: size.height,
          fontSize: 30,
          color: "white",
          background: "#8c1515",
        }}
        style={{ justifyContent: "center", padding: "25px 0px" }}
      />
      <div style={{ display: "flex", flexGrow: "1", overflow: "hidden" }}>
        <img
          src={Singapore}
          alt="Slider Image"
          height={"100%"}
          style={{ width: "100%", objectFit: "cover" }}
        />
      </div>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: 25,
          color: '#8c1515',
          fontWeight: 700,
          padding: '5px 0px 0px 0px'
        }}
      >
        LEAD Innovation Tour 2024
      </p>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: 20,
          padding: "10px 0px",
          color: '#8c1515',
          fontWeight: 700
        }}
      >
        An Event run by(and for) Stanford LEAD Participants/Alumni
      </p>
    </div>
  );
};

export default Countdown;
