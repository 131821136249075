import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Slider = ({ sliders }) => {
  return (
    <div style={{ display: "flex", height: "85vh", width: "100%" }}>
      <Swiper
        spaceBetween={100}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <div className="swiper">
          {sliders.map((slide, index) => {
            return (
              <div className="slider-image" key={index}>
                <SwiperSlide>
                  {slide.isComponent ? (
                    slide.src
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img
                        src={slide.src}
                        alt="Slider Image"
                        className="slider"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        height={"100%"}
                      />
                    </div>
                  )}
                </SwiperSlide>
              </div>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
};

export default Slider;